
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .overview-img-wide {
        margin-left: -18.3333%;
        &.col-md-6 {
            flex: 0 0 calc(50% + 18.3333%);
            max-width: calc(50% + 18.3333%);
        }
    }
}
@media (min-width: 1200px) {
}
